import React from "react";

function Search(props) {
  return (
    <div className="w-[35%] bg-gray-50 justify-center opacity-90 flex">
      <div className="w-[82%] mt-12">
        <div className="flex flex-row justify-between items-center my-3 ">
          <div>
            <h2 className="font-semibold text-[1.3rem]">Search</h2>
          </div>
        </div>
        <input
          className="bg-white flex flex-1 w-full text-[1rem] p-3 rounded-lg border border-zinc-200"
          placeholder="Search..."
        />
      </div>
    </div>
  );
}

export default Search;
