import React from "react";
import Navbar from "../components/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Search_btn from "../assets/Search_btn.svg";
import Navigate from "../assets/navigate.svg";

function AuthenicatedLayout() {
  const location = useLocation();
  return (
    <div className="w-full flex flex-row ">
      <Navbar />
      <div
        className={`flex  flex-1 justify-between pb-20 ${
          location.pathname === "/dashboard"
            ? "bg-background"
            : location.pathname === "/user-profile" ||
              location.pathname === "/user-history"
            ? "bg-background-2"
            : "bg-background-1"
        }`}
      >
        <Outlet />
        <div className="mt-10 mr-16 flex flex-col justify-between">
          <form className="flex flex-row gap-4">
            <select className="border border-zinc-200 w-24 px-2 rounded-sm">
              <option>Warri</option>
            </select>
            <select className="border border-zinc-200 w-40 px-2 rounded-sm">
              <option>Agbor Center</option>
            </select>
            <button className="bg-[green] py-3 px-4 rounded-md">
              <img src={Search_btn} alt="search btn" />
            </button>
          </form>
          <div className="flex justify-end">
            <button className="w-[10rem]">
              <img src={Navigate} alt="navigate" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthenicatedLayout;
