import React from "react";
import UserFace from "../assets/user-face.svg";
import Message from "../assets/Message.svg";
import Call from "../assets/Phone.svg";
import Manager from "../assets/manager.svg";
import Location from "../assets/location.svg";
import { Link } from "react-router-dom";

const tons = [
  {
    img: Location,
    tons: "175 Tons",
    text: "Total Recovered Today",
  },
  {
    img: Location,
    tons: "125 metres",
    text: "Total Distance Covered Today",
  },
  {
    img: Location,
    tons: "3hrs 45mins",
    text: "Total Time Elapse Today",
  },
];

function LiveTracker(props) {
  return (
    <div className="w-[35%] bg-gray-50 justify-center opacity-90 flex">
      <div className="w-[82%] mt-12">
        <input
          className="bg-white flex flex-1 w-full text-[1rem] p-3 rounded-lg border border-zinc-200"
          placeholder="Search..."
        />
        <div className="flex flex-row justify-between items-center my-3 ">
          <div>
            <h2 className="font-semibold text-[1.3rem]">Live Tracker</h2>
          </div>
          <div>
            <span className="text-zinc-600 text-[0.7rem]">
              Thursday 4th January, 2022 | 14:00
            </span>
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-lg border border-zinc-300 py-5 px-5 mb-6">
          <div className="flex flex-col border-b border-zinc-300 py-3 gap-3 justify-between w-full mb-2">
            <div className="flex flex-row gap-2">
              <img src={UserFace} alt="user pic" />
              <div>
                <h5 className="font-bold text-black text-[1rem]">
                  Musa Mohammed
                </h5>
                <span className="text-zinc-600 font-normal text-[1rem]">
                  ID: 1232324
                </span>
              </div>
            </div>
            <div className="flex gap-3">
              <Link
                to="/user-profile"
                className="bg-green-100 text-[green] p-2 text-[0.9rem]"
              >
                View Profile
              </Link>
              <Link
                to="/user-history"
                className="bg-green-100 text-[green] p-2 text-[0.9rem]"
              >
                View History
              </Link>
            </div>
            {/* <div className="flex flex-1 flex-row-reverse h-full items-end">
              <Link className="text-[0.9rem] text-[green] font-normal flex flex-1 flex-row-reverse underline underline-offset-4">
                View History
              </Link>
            </div> */}
          </div>
          {tons.map((ton) => {
            return (
              <div className="flex py-2 items-center justify-between w-full">
                <div className="flex flex-row gap-2 ml-2">
                  <img src={ton.img} alt="ton pic" />
                  <div className="leading-none">
                    <h5 className="font-bold text-black text-[0.9rem]">
                      {ton.tons}
                    </h5>
                    <span className="text-zinc-800 font-normal text-[0.6rem]">
                      {ton.text}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex border border-zinc-300 py-3 items-center justify-between w-full px-6 rounded-lg">
          <div className="flex flex-row gap-2">
            <img src={Manager} alt="user pic" />
            <div>
              <h5 className="font-bold text-black text-[1rem]">Kachi Ogala</h5>
              <span className="text-zinc-600 font-normal text-[1rem]">
                Manager
              </span>
            </div>
          </div>
          <div className="flex flex-1 gap-4 flex-row h-full justify-end">
            <img src={Call} alt="Manager" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveTracker;
