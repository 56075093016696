import React from "react";
import UserFace from "../assets/user-face.svg";
import Message from "../assets/Message.svg";
import Call from "../assets/Call.svg";
import Manager from "../assets/manager.svg";
import { Link } from "react-router-dom";

let customers = [
  {
    img: UserFace,
    location: "Ikeja",
    name: "Salim Mohammed",
  },
  {
    img: Manager,
    location: "Magodo",
    name: "Paul Saheed",
  },
  {
    img: UserFace,
    location: "Ajah",
    name: "John Doe",
  },
];
function Customers() {
  return (
    <div className="w-[35%] bg-gray-50 justify-center opacity-90 flex">
      <div className="w-[82%] mt-12">
        <input
          className="bg-white flex flex-1 w-full text-[1rem] p-3 rounded-lg border border-zinc-200"
          placeholder="Search..."
        />
        <div className="flex flex-row justify-between items-center my-3 ">
          <div>
            <h2 className="font-semibold text-[1.3rem]">Customers</h2>
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-lg border border-zinc-300 py-5 px-5">
          {customers.map((customer) => {
            return (
              <div className="flex border-b border-zinc-300 py-3 items-center justify-between w-full mb-2">
                <div className="flex flex-row gap-2">
                  <img src={customer.img} alt="user pic" />
                  <div>
                    <h5 className="font-bold text-black text-[1rem]">
                      {customer.name}
                    </h5>
                    <span className="text-zinc-600 font-normal text-[1rem]">
                      {customer.location}
                    </span>
                  </div>
                </div>
                <div className="flex flex-1 flex-row-reverse h-full items-end">
                  <Link className="text-[0.9rem] text-[green] font-normal flex flex-1 flex-row-reverse underline underline-offset-4">
                    View History
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Customers;
