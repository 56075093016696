import React from "react";
import Mrbin from "../assets/Mrbin.svg";
import { Link, NavLink } from "react-router-dom";
import Dashboard from "../assets/Category.svg";
import Discovery from "../assets/Discovery.svg";
import Calendar from "../assets/Calendar.svg";
import Search from "../assets/Search.svg";
import Setting from "../assets/Setting.svg";
import ProfileImage from "../assets/ProfileImage.svg";

function Navbar(props) {
  return (
    <div className="flex flex-col w-[7%] items-center border-r">
      <img src={Mrbin} alt="Mr bin logo" className="w-[4rem] mt-12 mb-20" />
      <ul className="flex flex-col gap-10">
        <li>
          <NavLink to={"/dashboard"}>
            <img src={Dashboard} className="w-[2rem]" alt="dashboard" />
          </NavLink>
        </li>
        <li>
          <NavLink to={"live-tracker"} className="flex ">
            <img src={Discovery} alt="user" className="w-[2rem]" />
          </NavLink>
        </li>
        <li>
          <NavLink to={"/collections"}>
            <img src={Calendar} className="w-[2rem]" alt="routing" />
          </NavLink>
        </li>
        <li>
          <NavLink to={"/search"}>
            <img src={Search} className="w-[2rem]" alt="calendar" />
          </NavLink>
        </li>
      </ul>
      <div className="flex  justify-end flex-1 mb-5">
        <ul className="flex justify-end gap-5 flex-col">
          <li className="flex">
            <NavLink to={"/"} className="flex ">
              <img src={Setting} alt="user" className="w-[2rem]" />
            </NavLink>
          </li>
          <li className="flex">
            <NavLink to={"/"} className="flex ">
              <img src={ProfileImage} alt="user" className="w-[2rem]" />
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
