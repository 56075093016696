import React from "react";
import { Outlet } from "react-router-dom";
import Mrbin from "../assets/Mrbin.svg";
function AuthenicateLayout(props) {
  return (
    <div className="flex w-full h-dvh">
      <div className="flex w-[50%] bg-[green] items-center justify-center">
        <div className="flex flex-col justify-center">
          <img src={Mrbin} className="w-[10rem]" alt="logo" />
          <h1 className="text-[3rem] text-white">
            Transforming Tomorrow <br /> by Recovering Today's
            <br /> Waste.
          </h1>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default AuthenicateLayout;
