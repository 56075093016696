import logo from "./logo.svg";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthenicatedLayout from "./layouts/AuthenicatedLayout";
import Dashboard from "./pages/Dashboard";
import AuthenicateLayout from "./layouts/AuthenicateLayout";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Collection from "./pages/Collection";
import Customers from "./pages/Customers";
import LiveTracker from "./pages/LiveTracker";
import Search from "./pages/Search";
import UserProfile from "./pages/UserProfile";
import UserHistory from "./pages/UserHistory";

function App() {
  let routerOutlet = createBrowserRouter([
    {
      path: "/",
      element: <AuthenicateLayout />,
      children: [
        {
          path: "",
          element: <Signin />,
        },
        {
          path: "/signup",
          element: <Signup />,
        },
      ],
    },
    {
      element: <AuthenicatedLayout />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/collections",
          element: <Collection />,
        },
        {
          path: "/customers",
          element: <Customers />,
        },
        {
          path: "/live-tracker",
          element: <LiveTracker />,
        },
        {
          path: "/search",
          element: <Search />,
        },
        {
          path: "/user-profile",
          element: <UserProfile />,
        },
        {
          path: "/user-history",
          element: <UserHistory />,
        },
      ],
    },
  ]);
  return <RouterProvider router={routerOutlet} />;
}

export default App;
