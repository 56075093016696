import React from "react";
import User from "../assets/3User.svg";
import Send from "../assets/Send.svg";
import Buy from "../assets/Buy.svg";
import UserFace from "../assets/user-face.svg";
import { Link } from "react-router-dom";

const collectors = [
  {
    img: UserFace,
    name: " Musa Mohammed",
    id: 1232324,
    milage: "375m",
    tons: "120Tons",
  },
  {
    img: UserFace,
    name: " Musa Mohammed",
    id: 1232324,
    milage: "375m",
    tons: "120Tons",
  },
  {
    img: UserFace,
    name: " Musa Mohammed",
    id: 1232324,
    milage: "375m",
    tons: "120Tons",
  },
  {
    img: UserFace,
    name: " Musa Mohammed",
    id: 1232324,
    milage: "375m",
    tons: "120Tons",
  },
  {
    img: UserFace,
    name: " Musa Mohammed",
    id: 1232324,
    milage: "375m",
    tons: "120Tons",
  },
];

function Dashboard(props) {
  return (
    <div className="w-[35%] bg-gray-50 justify-center opacity-90 flex">
      <div className="w-[82%] mt-12">
        {/* <input
          className="bg-white flex flex-1 w-full text-[1rem] p-3 rounded-lg border border-zinc-200"
          placeholder="Search..."
        /> */}
        <div className="flex flex-row justify-between items-center my-6">
          <div>
            <h2 className="font-semibold text-[1.3rem]">Dashboard</h2>
          </div>
          <div>
            <span className="text-zinc-600 text-[0.7rem]">
              Thursday 4th January, 2022 | 14:00
            </span>
          </div>
        </div>
        <div className="flex gap-4 flex-col mb-10">
          <div className="flex rounded-lg border border-zinc-300 justify-center flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={User} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Active Collectors
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                16
              </span>
            </div>
          </div>
          <div className="flex rounded-lg border border-zinc-300 justify-center flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={Buy} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Tonnage Recovered
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                200T
              </span>
            </div>
          </div>
          <div className="flex rounded-lg border border-zinc-300 justify-around flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={Send} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Distance Recovered
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                4,500km
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center my-3 ">
          <div>
            <h2 className="font-semibold text-[1.3rem]">Collectors</h2>
          </div>
          <div>
            <Link className="text-[1rem] text-[green] font-medium flex flex-1 flex-row-reverse underline underline-offset-4">
              View All
            </Link>
          </div>
        </div>
        {collectors.map((user) => {
          return (
            <div className="flex border-b border-zinc-300 py-3 items-center justify-between">
              <div className="flex flex-row gap-2">
                <img src={user.img} alt="user pic" />
                <div>
                  <h5 className="font-bold text-black text-[1rem]">
                    {user.name}
                  </h5>
                  <span className="text-zinc-600 font-normal text-[1rem]">
                    {user.id}
                  </span>
                </div>
              </div>
              <div>
                <span className="text-zinc-600 font-normal text-[1rem]">
                  {user.milage}
                </span>
              </div>
              <div>
                <span className="text-zinc-600 font-normal text-[1rem]">
                  {user.tons}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Dashboard;
