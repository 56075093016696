import React from "react";
import { Link } from "react-router-dom";

function Signin() {
  return (
    <div className="flex flex-1 items-center justify-left">
      <div className="flex flex-col w-[70%] pl-32">
        <span className="text-[3rem] text-[green] font-bold">Login</span>
        <form className="flex flex-col gap-4 mb-10">
          <input
            type="text"
            className="text-black bg-white p-4 rounded-lg border border-zinc-400"
            placeholder="Email Address"
          />
          <input
            type="password"
            className="text-black bg-white p-4 rounded-lg border border-zinc-400"
            placeholder="Password"
          />
        </form>
        <Link
          to="/dashboard"
          className="bg-[green] text-white w-full p-4 text-center rounded-lg"
        >
          Login
        </Link>
      </div>
    </div>
  );
}

export default Signin;
