import React from "react";
import UserFace from "../assets/user-face.svg";
import User from "../assets/3User.svg";
import Send from "../assets/Send.svg";
import Buy from "../assets/Buy.svg";
import Amount from "../assets/Amount.svg";
import { Link } from "react-router-dom";
import Arrow from "../assets/Arrow-Left.svg";

const history = [
  {
    date: "21-Jan-24",
    distance: "4km",
    tonnage: "200 tons",
  },
  {
    date: "21-Jan-24",
    distance: "4km",
    tonnage: "200 tons",
  },
  {
    date: "21-Jan-24",
    distance: "4km",
    tonnage: "200 tons",
  },
  {
    date: "21-Jan-24",
    distance: "4km",
    tonnage: "200 tons",
  },
  {
    date: "21-Jan-24",
    distance: "4km",
    tonnage: "200 tons",
  },
];

function UserHistory(props) {
  return (
    <div className="w-[35%] bg-gray-50 justify-center opacity-90 flex">
      <div className="w-[82%] mt-12">
        {/* <input
      className="bg-white flex flex-1 w-full text-[1rem] p-3 rounded-lg border border-zinc-200"
      placeholder="Search..."
    /> */}
        <div className="flex flex-row justify-between items-center my-6">
          <div>
            <h2 className="font-semibold text-[1.3rem]">History</h2>
          </div>
          <div>
            <span className="text-zinc-600 text-[0.7rem]">
              Thursday 4th January, 2022 | 14:00
            </span>
          </div>
        </div>
        <Link to="/live-tracker" className="mb-3">
          <img src={Arrow} alt="back" />
        </Link>
        <div className="flex flex-row gap-2 mb-5">
          <img src={UserFace} alt="user pic" />
          <div>
            <h5 className="font-bold text-black text-[1rem]">Musa Mohammed</h5>
            <span className="text-zinc-600 font-normal text-[1rem]">
              ID: 1232324
            </span>
          </div>
        </div>
        <div className="flex gap-4 flex-col mb-10">
          <div className="flex rounded-lg border border-zinc-300 justify-center flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={Amount} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Total Amount Earned
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                N150,000
              </span>
            </div>
          </div>
          <div className="flex rounded-lg border border-zinc-300 justify-center flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={User} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Numbers of Days Active
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                16
              </span>
            </div>
          </div>
          <div className="flex rounded-lg border border-zinc-300 justify-around flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={Buy} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Total Tonnage Recovered
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                200T
              </span>
            </div>
          </div>
          <div className="flex rounded-lg border border-zinc-300 justify-around flex-row py-4 p-2">
            <div className="w-[90%] flex  ">
              <div className="w-[70%] flex gap-4 items-center">
                <img src={Send} alt="User" className="w-[2rem]" />
                <span className="text-zinc-700 text-[1rem] font-normal">
                  Total Distance Recovered
                </span>
              </div>
              <span className="text-black font-bold text-[1.7rem] flex flex-1 flex-row-reverse">
                4,500km
              </span>
            </div>
          </div>
        </div>
        <div className="flex border-b border-zinc-300 py-3 items-center justify-between">
          <h5 className="text-black text-[0.8rem] font-bold w-[20%]">Date</h5>
          <h5 className="text-black text-[0.8rem] font-bold w-[20%]">
            Distance Covered
          </h5>
          <h5 className="text-black text-[0.8rem] font-bold w-[20%]">
            Tonnage Recovered
          </h5>
        </div>
        {history.map((user) => {
          return (
            <div className="flex border-b border-zinc-300 py-3 items-center justify-between">
              <span>{user.date}</span>
              <span>{user.distance}</span>
              <span>{user.tonnage}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UserHistory;
