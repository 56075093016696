import React from "react";
import Call from "../assets/Phone.svg";
import Manager from "../assets/manager.svg";
import Arrow from "../assets/Arrow-Left.svg";
import UserFace from "../assets/user-face.svg";
import { Link } from "react-router-dom";

function UserProfile(props) {
  return (
    <div className="w-[35%] bg-gray-50 justify-center opacity-90 flex">
      <div className="w-[82%] mt-12">
        <div className="flex flex-row justify-between items-center my-3 ">
          <div>
            <h2 className="font-semibold text-[1.3rem]">Profile</h2>
          </div>
          <div>
            <span className="text-zinc-600 text-[0.7rem]">
              Thursday 4th January, 2022 | 14:00
            </span>
          </div>
        </div>
        <Link to="/live-tracker" className="mb-3">
          <img src={Arrow} alt="back" />
        </Link>
        <div className="flex flex-row gap-2 mb-5">
          <img src={UserFace} alt="user pic" />
          <div>
            <h5 className="font-bold text-black text-[1rem]">Musa Mohammed</h5>
            <span className="text-zinc-600 font-normal text-[1rem]">
              ID: 1232324
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-lg border border-zinc-300 py-5 px-5 mb-6">
          <h2 className="text-[green] text-[0.8rem] font-bold">Bio</h2>
          <p className="text-zinc-600 text-[0.8rem]">
            Phone Number: <span className="text-black">081232348594</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            Home Address:{" "}
            <span className="text-black">
              5 Abdullahi street, Akoka, Yaba, Lagos
            </span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            Date of Birth:
            <span className="text-black">4th October, 1996</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            State of Origin: <span className="text-black">Kaduna</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            NIN: <span className="text-black">3344334455</span>
          </p>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-lg border border-zinc-300 py-5 px-5 mb-6">
          <h2 className="text-[green] text-[0.8rem] font-bold">Next of Kin</h2>
          <p className="text-zinc-600 text-[0.8rem]">
            Name: <span className="text-black">Aishat Mohammed</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            Relationship:
            <span className="text-black">Wife</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            Phone Number:
            <span className="text-black">234895409696</span>
          </p>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-lg border border-zinc-300 py-5 px-5 mb-6">
          <h2 className="text-[green] text-[0.8rem] font-bold">Bank Details</h2>
          <p className="text-zinc-600 text-[0.8rem]">
            Account Name: <span className="text-black">Musa Mohammed</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            Account Number:
            <span className="text-black">123323124</span>
          </p>
          <p className="text-zinc-600 text-[0.8rem]">
            Bank:
            <span className="text-black">First Bank</span>
          </p>
        </div>
        <div className="flex border border-zinc-300 py-3 items-center justify-between w-full px-6 rounded-lg">
          <div className="flex flex-row gap-2">
            <img src={Manager} alt="user pic" />
            <div>
              <h5 className="font-bold text-black text-[1rem]">Kachi Ogala</h5>
              <span className="text-zinc-600 font-normal text-[1rem]">
                Manager
              </span>
            </div>
          </div>
          <div className="flex flex-1 gap-4 flex-row h-full justify-end">
            <img src={Call} alt="Manager" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
